<template>
    <div class="smsSettings">
        <div class="ml-20 mt-20 mb-20">能力号IP</div>
        <div class="flex-nowrap-space-around-other">
            <div class="input-searchFrom-width-600">
                <el-form ref="upform" size="small" :model="upform" label-width="120px" :rules="rules">
                    <el-form-item label="查询列表：" prop="number">
                        <el-input
                            v-model="upform.number"
                            type="textarea"
                            placeholder="请输入号码-IP(换行可输入多个)"
                            :autosize="{ minRows: 6, maxRows: 8 }"
                        />
                        <div>一行一个 号码-IP <br>
                        列如 188********-183.**.*.**</div>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button class="yes-green-bg" @click="theQuery('upform')">查 询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="input-searchFrom-width-600 show-boxs">
                <div v-if="dataList.length == 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <div v-else>
                    <div v-for="(item,i) in dataList"  :key="i" class="flex-nowrap-space-around item-box-other">
                        <div>{{ item.number }}</div>
                        <div>{{ item.statusName }}</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import ctcc from '@/api/open/ctcc'
export default {
    components: {

    },
    data () {
        const checkNumber = (rule, value, callback) => {
            let num = 0,types = null,errorItem;
            if(value == null){
                callback(new Error('请填写号码 (多个号码请换行输入)'))
            }else if(value.length == 0){
                callback(new Error('请填写号码 (多个号码请换行输入)'))
            }else{
                let newArr = []
                newArr = value.split(/[(\r\n)\r\n]+/)
                newArr.forEach((item,i)=>{
                    if(item.match(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/) == null){
                    // if(item.match(/^(1[3|4|5|6|7|8|9])\\d{9}$|^0\\d{2,3}-?\\d{7,8}$|^9\\d{9}/) == null){
                        // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
                        num++
                        types = i
                        errorItem = item
                    }
                })
                if( num != 0 ){
                    callback(new Error('请输入正确的号码 (请检查第'+(types+1)+"行："+ errorItem +")"))
                }else{
                    callback()
                }
            }
        }
        return {
            upform:{},
            listArr:[
          
          ],
            dataList:[],
            rules: {
                interfaceId: [
                    {
                    required: true,
                    message: '请选择查询的接口账户',
                    trigger: 'change',
                    },
                ],
                number: [
                    { required: true, message: '请输入查询内容', trigger: 'change' },
                ],
            }
        }
    },
    created() {
        
    },
    watch: {

    },
    mounted() {
             this.host()   
    },
    methods: {
        host(){
               const currentDomain = window.location.host;
console.log(currentDomain); 
            if (currentDomain=='tool.hiszy.com') {
                 this.listArr=[{
                name:'XZ电信回拨(新)',
                id:1912
            }
            
            ,{
                name:'DF电信回拨(新)',
                id:2516
            }
          
          ]
            }else{
                    this.listArr=[{
                name:'SC电信回拨(新) ',
                id:3440
            }
            
          
          
          ]
            }
        },
       theQuery(formName) {
    this.$refs[formName].validate((valid) => {
        if (valid) {
            // 获取输入内容并按行分割
            let arrLines = this.upform.number.split(/[(\r\n)\r\n]+/);
            let requestParams = [];
            this.dataList=[]
            arrLines.forEach((line) => {
                line = line.trim();
                if (line) {
                    let [number, ip] = line.split("-");
                    if (number && ip) {
                        requestParams.push({
                            number: number.trim(),
                            ip: ip.trim()
                        });
                    }
                }
            });

            console.log(requestParams); // 打印解析后的参数列表

            // 遍历请求参数列表逐一调用接口
            Promise.all(
                requestParams.map(params =>
                    ctcc.reportCtccIp({
                        interfaceId: this.upform.interfaceId,
                        number: params.number,
                        ip: params.ip
                    }).then(res => {
                        // 处理成功结果
                        if (res.code == '0000') {
                            console.log(res);
                            this.dataList.push({
                                number: params.number,
                                statusName: "请求成功"
                            });
                        } else {
                            // 处理失败结果
                            this.dataList.push({
                                number: params.number,
                                statusName: `请求失败: ${res.message || "未知错误"}`
                            });
                        }
                    }).catch(error => {
                        // 处理接口异常
                        console.log(error);
                        this.dataList.push({
                            number: params.number,
                            statusName: `请求失败: ${error.data.message || "接口调用异常"}`
                        });
                    })
                )
            ).then(() => {
                console.log("所有请求已完成");
            }).catch(() => {
                console.error("部分请求失败");
            });
        } else {
            console.log("error submit!!");
            return false;
        }
    });
},
        unique(arr) {
            const res = new Map();
            return arr.filter((a) => !res.has(a) && res.set(a, 1))
        },
    }
}
</script>

<style lang="scss" scoped>
    .smsSettings{
    

    }
    .show-boxs{
        height: 100%;
        border: 1px solid #0000;
    }
    .item-box-other{
        padding: 8px 16px;
        margin-bottom: 10px;
        border-radius: 4px;
        background: #ebebeb;
    }
</style>